import React from "react";
import Footer from "../components/Footer";

const about = () => {
  return (
    <>
      <div className="abt-sec1">
        <div className="abt-sec1-left">
          <h1>About Us</h1>
          <p>
            Liteagle LLP (“Firm”) is a firm situated at Delhi, founded by Mr.
            Shashank Singh and Mrs. Swati Singh and is having professionals like
            Advocates, Company Secretaries and Charted Accountants in its
            portfolio.
          </p>
        </div>
        <div className="abt-sec1-right">
          <img
            src="./img/about-us-page/ruthson-zimmerman-Ws4wd-vJ9M0-unsplash.jpg"
            alt=""
          />
        </div>
      </div>

      <div
        className="abt-sec2 animate__animated animate__backInUp"
        data-aos="fade-up"
      >
        <div className="abt-sec2-box">
          <div className="abt-sec2-box1-left abt-sec2-box-left">
            <p>
              Firm has been founded to provide effective and innovative legal
              solutions to our clients in a time bound manner adhering to best
              acceptable standards of quality. The firm’s motive is to act with
              fairness, integrity, ethics, diligence and social responsibility.
            </p>
          </div>
          <div className="abt-sec2-box1-right abt-sec2-box-right"></div>
        </div>

        <div className="abt-sec2-box">
          <div className="abt-sec2-box2-right abt-sec2-box-right"></div>
          <div className="abt-sec2-box2-left abt-sec2-box-left">
            <p>
              The Firm has access to network of high quality experienced lawyers
              and professionals in all major commercial cities of India. As
              such, the Firm in well placed to service the requirements of its
              clients across India in the fields of Employment Laws, Corporate
              and Commercial laws including the compliance under the said laws.
            </p>
          </div>
        </div>

        <div className="abt-sec2-box">
          <div className="abt-sec2-box3-left abt-sec2-box-left">
            <p>
              The Firm is professionally equipped to handle large sized and
              complex corporate transactions like Mergers & Acquisitions,
              Corporate Restructuring, Joint Ventures, Private Equity and
              Venture Capital Investment Transactions, Real Estate Transactions,
              Corporate Taxation, Corporate Compliances etc.
            </p>
          </div>
          <div className="abt-sec2-box3-right abt-sec2-box-right"></div>
        </div>
      </div>

      <div className="abt-sec3" data-aos="fade-up">
        <div className="abt-sec3-left">
          <h1>Come, Know Us More !</h1>
          <p>
            The firm is also having a vast experience of handling the industrial
            relations related matters like strikes, closures, shifting,
            transfers, general demands, etc. and is advising on the day to day
            issues related to the same.
          </p>
        </div>
        <div className="abt-sec3-right">
          <div className="abt-sec3-box">
            <i className="icofont-law-order"></i>
            <p>
              The Firm offers proven capabilities in litigation and dispute
              resolution practice areas, especially in handling litigation
            </p>
          </div>
          <div className="abt-sec3-box">
            <i className="icofont-law-alt-2"></i>
            <p>
              The Firm is a one stop shop for all the requirements of a
              corporate entity
            </p>
          </div>
          <div className="abt-sec3-box">
            <i className="icofont-lawyer-alt-2"></i>
            <p>
              It provides wide range of services to its clients from Consultancy
              to Compliances and legal advice to litigation handling.
            </p>
          </div>
          <div className="abt-sec3-box">
            <i className="icofont-law-scales"></i>
            <p>
              Firm in well placed to service the requirements of its clients
              across India in the fields of Employment Laws, Corporate and
              Commercial laws including the compliance under the said laws.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default about;
