import "./App.css";
import { Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Index from "./pages/Index";
import team from "./pages/team";
import service from "./pages/service";
import about from "./pages/about";
import contact from "./pages/contact";
import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Disclaimer from "./components/Disclaimer";
function App() {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const yes = localStorage.getItem("acceptCondition");
    if (yes) setShow(false);
    else setShow(true);
  }, []);
  const accept = () => {
    localStorage.setItem("acceptCondition", true);
    setShow(false);
  };
  return (
    <>
      <Modal
        center
        showCloseIcon={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        blockScroll
        open={show}
      >
        <Disclaimer accept={accept} />
      </Modal>
      <Navbar />
      <Switch>
        <Route exact path={"/"} component={Index} />
        <Route exact path={"/team"} component={team} />
        <Route exact path={"/service"} component={service} />
        <Route exact path={"/about"} component={about} />
        <Route exact path={"/contact"} component={contact} />
      </Switch>
    </>
  );
}

export default App;
