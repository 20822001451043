import React from "react";
import Footer from "../components/Footer";

const Index = () => {
  return (
    <>
      <div className="banner-image w-100 vh-100 d-flex justify-content-center align-items-center">
        <div className="sec1-content text-center">
          <h1 className="animate__animated animate__backInUp">LITEAGLE</h1>
          <h4 className="animate__animated animate__backInLeft animate__delay-1s">
            Ease your business with our legal expertise
          </h4>
          <h2 className="animate__animated animate__backInLeft animate__delay-1s">
            Professional and Reliable Legal Services
          </h2>
          <h3 className="animate__animated animate__backInRight animate__delay-1s">
            Our highly motivated team of professionals helps our clients by
            giving tailored and most reliable legal solutions for the challenges
            faced by them. We at Liteagle gives the client very responsive and
            strategic advice in respect of client’s legal queries. In Liteagle
            the team is devoted towards the solutions oriented approach.
          </h3>
        </div>
      </div>
      <div className="sec2">
        <div className="sec2-inner">
          <div className="sec2-left" data-aos="fade-up">
            <img src="../img/about-us/1.jpg" className="sec2-img1" alt="" />
            <img src="../img/about-us/2.jpg" className="sec2-img2" alt="" />
          </div>
          <div className="sec2-right" data-aos="fade-up">
            <h1>About Us</h1>
            <p>
              We specialize in services for labour laws, employment laws,
              corporate laws and many more fields through our highly trained
              professional. We, Liteagle LLP, situated at Safdarjung Enclave,
              Delhi, are a one stop shop offering a wide array of legal
              services. We work professionally and ethically with a result
              oriented approach. We are strongly dedicated to our work and our
              services are remarkable.
            </p>
            <a href="about-us.html">Read More</a>
          </div>
        </div>
      </div>
      <div className="sec3">
        <div className="sec3-left" data-aos="fade-up">
          <h1>Having 10+ Years of Experience in Legal Service</h1>
          <h2>
            <i className="fas fa-check-square"></i>Expert Legal Services
          </h2>
          <h2>
            <i className="fas fa-check-square"></i>Expert Attorneys
          </h2>
          <h2>
            <i className="fas fa-check-square"></i>Highly Recommended
          </h2>
        </div>
        <div className="sec3-right" data-aos="fade-up">
          <div className="sec3-box sec3-box1">
            <div className="sec3-box-top">
              <i className="far fa-thumbs-up"></i>
              <h5>Highest Success Rate Guaranteed</h5>
            </div>
          </div>

          <div className="sec3-box sec3-box2">
            <div className="sec3-box-top">
              <i className="far fa-thumbs-up"></i>
              <h5>Highly Recommended</h5>
            </div>
          </div>

          <div className="sec3-box sec3-box3">
            <div className="sec3-box-top">
              <i className="far fa-thumbs-up"></i>
              <h5>Expert Legal Services</h5>
            </div>
          </div>

          <div className="sec3-box sec3-box4">
            <div className="sec3-box-top">
              <i className="far fa-thumbs-up"></i>
              <h5>Ensure Quality Support</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="sec4" data-aos="fade-up">
        <h1>Our Services</h1>
        <div className="sec4-bottom">
          <div className="sec4-box sec4-box1">
            <h1>Employment Law</h1>
          </div>
          <div className="sec4-box sec4-box2">
            <h1>Industrial Relations</h1>
          </div>
          <div className="sec4-box sec4-box3">
            <h1> Corporate Law</h1>
          </div>
          <div className="sec4-box sec4-box2">
            <h1>Commercial Law </h1>
          </div>
          <div className="sec4-box sec4-box3">
            <h1>Contracts</h1>
          </div>
          <div className="sec4-box sec4-box1">
            <h1>Disputes Resolution</h1>
          </div>
        </div>

        <h2>Why us</h2>
        <div className="sec4-down">
          <div className="sec4-boxx sec4-boxx1">
            <i className="fas fa-trophy"></i>
            <p>
              We work professionally and ethically with a result oriented
              approach.
            </p>
          </div>
          <div className="sec4-boxx sec4-boxx2">
            <i className="fas fa-award"></i>
            <p>
              We are strongly dedicated to our work and our services are
              remarkable.
            </p>
          </div>
          <div className="sec4-boxx sec4-boxx3">
            <i className="fas fa-hand-holding-usd"></i>
            <p>
              We avail prompt and responsible responses at affordable charges.
            </p>
          </div>
        </div>

        <a href="our-services.html">Read More</a>
      </div>

      <div className="sec5" data-aos="fade-up">
        <div className="container">
          <div className="section-title">
            <h2>Testimonials</h2>
            <p>See what our clients say about Us !!</p>
          </div>
        </div>
        <div className="testimonials-carousel-wrap">
          <div className="listing-carousel-button listing-carousel-button-next">
            <i className="fa fa-caret-right" style={{ color: "#fff" }}></i>
          </div>
          <div className="listing-carousel-button listing-carousel-button-prev">
            <i className="fa fa-caret-left" style={{ color: "#fff" }}></i>
          </div>
          <div className="testimonials-carousel">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testi-item">
                    <div className="testi-avatar">
                      <img src="../img/testimonial/vaibhav.jpeg" alt="" />
                    </div>
                    <div className="testimonials-text-before">
                      <i className="fa fa-quote-right"></i>
                    </div>
                    <div className="testimonials-text">
                      <div className="listing-rating">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                      <p>
                        Professional. Efficient. Personable. The team at
                        Liteagle executed my dissolution expeditiously and
                        fairly. Highly recommend their services. We can't thank
                        Liteagle enough for their care, work and time on the
                        case.
                      </p>
                      <div className="testimonials-avatar">
                        <h3>Vaibhav</h3>
                      </div>
                    </div>
                    <div className="testimonials-text-after">
                      <i className="fa fa-quote-left"></i>
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testi-item">
                    <div className="testi-avatar">
                      <img src="../img/testimonial/ankush.jfif" alt="" />
                    </div>
                    <div className="testimonials-text-before">
                      <i className="fa fa-quote-right"></i>
                    </div>
                    <div className="testimonials-text">
                      <div className="listing-rating">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                      <p>
                        My experiences with Liteagle was stupendous through and
                        through. These people know their craft and walk the
                        extra mile for you. I also appreciated working with
                        people that care for you on a personal level instead of
                        just handling you as a “case“.
                      </p>
                      <div className="testimonials-avatar">
                        <h3>Ankush</h3>
                      </div>
                    </div>
                    <div className="testimonials-text-after">
                      <i className="fa fa-quote-left"></i>
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testi-item">
                    <div className="testi-avatar">
                      <img src="../img/testimonial/manoj-thakur.jfif" alt="" />
                    </div>
                    <div className="testimonials-text-before">
                      <i className="fa fa-quote-right"></i>
                    </div>
                    <div className="testimonials-text">
                      <div className="listing-rating">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                      <p>
                        Shashank helped me finish a long time case I’ve been
                        trying to wrap up for years. He was completely
                        understanding, professional and worked very diligently
                        to the end and I’m so grateful! Highly recommend!!
                      </p>
                      <div className="testimonials-avatar">
                        <h3>Manoj</h3>
                      </div>
                    </div>
                    <div className="testimonials-text-after">
                      <i className="fa fa-quote-left"></i>
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testi-item">
                    <div className="testi-avatar">
                      <img src="../img/testimonial/kaustubh.jfif" alt="" />
                    </div>
                    <div className="testimonials-text-before">
                      <i className="fa fa-quote-right"></i>
                    </div>
                    <div className="testimonials-text">
                      <div className="listing-rating">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                      <p>
                        We quickly had to get legal counsel and luckily for us,
                        we found Liteagle. The guidance was outstanding at all
                        times, prompt, timely and open for calls and quick
                        updates, and worked through all of the questions and
                        issues on this case.
                      </p>
                      <div className="testimonials-avatar">
                        <h3>Kaustubh</h3>
                      </div>
                    </div>
                    <div className="testimonials-text-after">
                      <i className="fa fa-quote-left"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tc-pagination"></div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Index;
