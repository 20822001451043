import React from "react";
import Footer from "../components/Footer";

const service = () => {
  return (
    <>
      <div className="ser-sec1">
        <div className="abt-sec1-left">
          <h1>Our Services</h1>
        </div>
        <div className="abt-sec1-right">
          <img
            src="./img/services/alvaro-serrano-hjwKMkehBco-unsplash.jpg"
            alt=""
          />
        </div>
      </div>

      <div className="ser-sec2 animate__animated animate__backInUp">
        <h1>Areas Of Practice</h1>

        <div className="ser-sec2-down">
          <div className="ser-box ser-box2">
            <h1>Employment and Industrial Laws</h1>
            <p>
              The Firm is having a dedicated team which exclusively focuses on
              Employment Laws and Industrial Relations related issues.
            </p>
          </div>

          <div className="ser-box ser-box1">
            <h1>Corporate, Commercial and Contracts</h1>
            <p>
              The Firm has expert team of professionals like Advocates, CSs and
              CAs who have in depth knowledge and experience in their subjects.
            </p>
          </div>

          <div className="ser-box ser-box3">
            <h1>Disputes Resolution</h1>
            <p>
              The Firm is having a very strong team of litigation lawyers who
              are taking care of the various cases of the Firm’s clients in
              various cities in India.
            </p>
          </div>
        </div>
      </div>
      <div className="ser-sec3" data-aos="fade-up">
        <div className="ser-sec3-inner">
          <h1>Employment Laws, Industrial Relations and Litigations</h1>
          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> Preparation of work regulations, HR Manual, Staff Handbook.</p>
          </div>

          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p>Standing Orders.</p>
          </div>

          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> Conducting Audits, Due Diligence.</p>
          </div>
          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> Compliance of Labour Laws.</p>
          </div>
          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> Preparation of Employees Code of Conducts.</p>
          </div>
          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> Preparing Sexual Harassment Policies.</p>
          </div>
          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> Drafting of Employment Contracts, etc.</p>
          </div>
          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p>
              {" "}
              Obtaining of various licenses and registrations under the Labour
              and Employment Laws like, shop and establishment registration,
              Factory licenses, Contract Labour Registrations and licenses,
              Professional Tax registrations, Labour Welfare Registrations,
              Building and other Construction workers Registrations, EPF
              registrations, ESI registrations, registration for migrant
              workers, etc.
            </p>
          </div>
        </div>
      </div>

      <div className="ser-sec3" data-aos="fade-up">
        <div className="ser-sec3-inner">
          <h1>Corporate, Commercial and Contracts</h1>
          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> Advising various corporate entities on the day to day basis.</p>
          </div>

          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p>
              Assist their clients in understating legal and regulatory aspects
              of investing in India and doing business in India.
            </p>
          </div>

          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> Seeking approvals from various authorities.</p>
          </div>
          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p>
              {" "}
              Drafting of and execution of legal documentation like shareholders
              agreement, joint venture agreement, IPR license and assignment
              agreements, business transfer agreements, share purchase/shares
              subscription agreements, lease agreements, conveyance and mortgage
              documents, software agreements including End-User agreement, joint
              development agreements, Merger and Acquisitions and related
              transactions including post-closing compliances, due diligence,
              matter related to the insolvency and restructuring.
            </p>
          </div>
          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p>
              {" "}
              Incorporation/setting up of suitable entity like Company, LLP,
              Branch Office, Representative Office, section 8 companies under
              Companies Act, 2013.
            </p>
          </div>
          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p>
              {" "}
              Corporate compliance service like, maintenance of minutes,
              conducting board meetings, general meetings, obtaining digital
              signatures, DINs, undertaking KYC compliances, undertaking annual,
              periodical and event base compliances, undertaking changes and
              amendments in registered offices, MOA and AOA.
            </p>
          </div>
          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p>
              {" "}
              Corporate Law and Companies Law related matters and disputes.
            </p>
          </div>
          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> Restoration of the Companies.</p>
          </div>
          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> Oppression and Mis-management.</p>
          </div>
          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> DIN restoration matters.</p>
          </div>
          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> Merger and De-mergers.</p>
          </div>
          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p>
              {" "}
              Provides legal services related to the compounding and condonation
              of offenses under the Companies Act, 2013.
            </p>
          </div>
        </div>
      </div>

      <div className="ser-sec3" data-aos="fade-up">
        <div className="ser-sec3-inner">
          <h1>Disputes Resolution</h1>
          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> Writ Petitions.</p>
          </div>

          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> Civil recovery matters.</p>
          </div>

          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> White collar crimes including frauds.</p>
          </div>

          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> Real Estate Matters.</p>
          </div>

          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> Contractual disputes.</p>
          </div>

          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p>Consumer and Service related disputes.</p>
          </div>

          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p> Commercial cases.</p>
          </div>

          <div className="ser-sec3-p">
            <i className="fas fa-star"></i>
            <p>MSME cases. </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default service;
