import React from "react";
import Footer from "../components/Footer";

const team = () => {
  return (
    <>
      <div className="te-sec1">
        <div className="abt-sec1-left">
          <h1>Our Team</h1>
        </div>
        <div className="abt-sec1-right">
          <img
            src="./img/team-page/krakenimages-Y5bvRlcCx8k-unsplash.jpg"
            alt=""
          />
        </div>
      </div>

      <div className="te-sec2 animate__animated animate__backInUp">
        <div className="te-left">
          <img src="img/team-page/shashank-sir.jpeg" alt="" />
        </div>
        <div className="te-right">
          <h1>Shashank Singh</h1>
          <p>
            Shashank is a member of Institute of Company Secretaries of India
            and law graduate from University of Delhi. Shashank is also visiting
            faculty at Lovely Professional University, Jalandhar, Punjab. He has
            also adjudged various moot courts at the Lovely Professional
            University, Jalandhar, Punjab. Shashank is a seasoned lawyer and
            enjoyed good command in appearing before various courts and
            Tribunals and strategizing & advising on litigation strategies.
            Shashank comes with a background of vast experience of more than
            seven years in the field of Employment Laws, Corporate and
            Commercial laws. Shashank handled day to day queries of the clients
            on aspects of various labour laws, corporate laws etc. Shashank is
            handling various clients in respect of litigation and compliances in
            Delhi NCR, Haryana, Punjab, Maharashtra and Uttar Pradesh etc. His
            forte lies in imparting training, holding seminar and workshop on
            POSH and Labour Law Compliances.
          </p>
          <div className="te-right-a">
            <a
              href="https://www.linkedin.com/in/shashank-singh-687a6153/"
              rel={"noreferrer"}
              target="_blank"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              href="mailto:Shashank@liteagle.com"
              rel={"noreferrer"}
              target="_blank"
            >
              <i className="fas fa-envelope"></i>
            </a>
          </div>
        </div>
      </div>

      <div className="te-sec2" data-aos="fade-up">
        <div className="te-left">
          <img src="img/team-page/female.png" alt="" />
        </div>
        <div className="te-right">
          <h1>Swati Singh</h1>
          <p>
            Swati is a member of Institute of Company Secretaries of India and
            law Graduate from Choudhary Charan Singh University and she has been
            practicing as Company Secretary since 2016 and has extensive
            experience in drafting corporate agreements, Service Contracts,
            Non-Disclosure agreements, employment contracts and other legal
            documents. She has been involved in assisting and guiding new
            companies and foreign clients in establishing their existence and
            setting up in India and advising them on day to day queries related
            to the corporate laws and Compliances.{" "}
          </p>
        </div>
      </div>

      <div className="te-sec2" data-aos="fade-up">
        <div className="te-left">
          <img src="img/team-page/male.png" alt="" />
        </div>
        <div className="te-right">
          <h1>Saurabh Munjal</h1>
          <p>
            Saurabh has vast experience in rendering quality advisory and core
            litigation support on employment law to various domestic and
            international business clients. He represents clients before fora
            across the country. His forte lies in advising national and
            international clients on negotiating agreements, due-diligence in
            regard to Industrial Laws and HR Compliances for the organizations
            and service providers. He provides support to clients on
            outsourcing/ contract labour system in their manufacturing plant. He
            advises clients on disciplinary process and represented them before
            various firms like High Court and various Tribunals and authorities.
            He conducted various seminars and workshops on IR and HR related
            issues.
          </p>
        </div>
      </div>

      <div className="te-sec2" data-aos="fade-up">
        <div className="te-left">
          <img src="img/team-page/sp-singh-chawla.jpeg" alt="" />
        </div>
        <div className="te-right">
          <h1>SP Singh Chawla</h1>
          <p>
            SP Singh is a Law Graduate from Bharati Vidyapeeth University (BVP),
            Pune and is a practicing advocate in Delhi and registered with Delhi
            High Court Bar Association since 2012. He has done LL.M (Corporate
            Law Specialisation) from National Law University, Delhi. He is also
            member of the Institute of Company Secretaries of India. He has also
            been a member of Corporate Law Committee of Northern India Regional
            Council (‘NIRC’) of Institute of Company Secretaries of India
            (‘ICSI’) for the year 2017-18. He is dealing in all kind of
            corporate law litigation matters inter-alia corporate restructuring,
            IBC matters and restoration along with advisory on corporate law
            matters, transactional matters and other matters related to civil
            litigation. He has taken various sessions at ICSI and ICAI (CWA) on
            different arenas of topics.
          </p>
        </div>
      </div>

      <div className="te-sec2" data-aos="fade-up">
        <div className="te-left">
          <img src="img/team-page/vivek-sir.jpeg" alt="" />
        </div>
        <div className="te-right">
          <h1>Vivek Singh</h1>
          <p>
            Vivek is a Law Graduate with a Bachelor's Degree in Commerce from
            Delhi University. He has over 6 years of experience specializing in
            Goods and Service Tax, Income Tax, Bookkeeping, and Accounting. As
            an advocate, he practices in the areas of Taxation Laws, Tax
            Planning, Accountancy, and Bookkeeping. Vivek's expertise lies in
            advising clients on various aspects of their financial management.
            He excels in managing day-to-day books of accounts, stock
            management, bank reconciliation, profit and loss management,
            conducting internal audits, and finalizing balance sheets for
            companies. His areas of practice include Goods and Service Tax,
            Income Tax Assessments, Accounts Management, and Bookkeeping. Vivek
            is dedicated to providing his clients with comprehensive and
            valuable guidance in navigating their financial responsibilities.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default team;
