import React from "react";

const Footer = () => {
  // if (window.location.href === "/")
  console.log(window.location.href);
  return (
    <footer>
      <div className="footer-inner">
        <div className="footer-box">
          <img src="./img/logo/logo.png" alt="" />
          <h3>LITEAGLE LLP</h3>
          <p>Ease your business with our legal expertise.</p>
        </div>
        <div className="footer-box">
          <h2>Quick Links</h2>
          <a href="about">About Us</a>
          <a href="service">Services</a>
          <a href="team">Our Team</a>
          <a href="contact">Contact Us</a>
        </div>
        <div className="footer-box">
          <h2>Reach Us</h2>
          <a
            href="https://g.page/r/Cd1QZb6NvbelEA0"
            target="_blank"
            className="footer-link"
            rel="noreferrer"
          >
            Liteagle LLP, B2/88, Safdarjung Enclave, New Delhi-110029.
          </a>
          <a href="tel:+91-7503576834" className="footer-link">
            M No. +91-7503576834
          </a>
          <a href="mailto:info@liteagle.com" className="footer-link">
            E-mail id- info@liteagle.com
          </a>
          <div className="sec1-social">
            <a
              href="https://www.facebook.com/Liteagle"
              rel="noreferrer"
              target="_blank"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              href="https://www.instagram.com/official_liteagle/"
              rel="noreferrer"
              target="_blank"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://twitter.com/Liteagle_office"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a href="mailto:info@liteagle.com" target="_blank" rel="noreferrer">
              <i className="far fa-envelope"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="down">© 2021 LITEAGLE LLP. All Rights Reserved.</div>
    </footer>
  );
};

export default Footer;
