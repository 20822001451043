import React from "react";

const Disclaimer = ({ accept }) => {
  return (
    <div className="dis">
      <h2>DISCLAIMER</h2>
      <p>
        The Bar Council of India does not permit advertisement or solicitation
        by advocates in any form or manner. By accessing this website,
        www.liteagle.com, you acknowledge and confirm that you are seeking
        information relating to Liteagle LLP of your own accord and that there
        has been no form of solicitation, advertisement or inducement by
        Liteagle LLP or its members. The content of this website is for
        informational purposes only and should not be interpreted as soliciting
        or advertisement. No material/information provided on this website
        should be construed as legal advice. Liteagle LLP shall not be liable
        for consequences of any action taken by relying on the
        material/information provided on this website. The contents of this
        website are the intellectual property of Liteagle LLP.
      </p>
      <button onClick={accept}>Accept</button>
    </div>
  );
};

export default Disclaimer;
