import React from "react";
import Footer from "../components/Footer";

const contact = () => {
  return (
    <>
      <div className="cu-sec1">
        <div className="abt-sec1-left">
          <h1>Contact Us</h1>
        </div>
        <div className="abt-sec1-right">
          <img
            src="./img/contact-us/pawel-czerwinski--0xCCPIbl3M-unsplash.jpg"
            alt=""
          />
        </div>
      </div>

      <div className="cu-sec2 animate__animated animate__backInUp">
        <div className="contact-form">
          <div className="first-container">
            <div className="info-container">
              <div>
                <h3>Address</h3>
                <p>Liteagle LLP, B2/88, Safdarjung Enclave, New Delhi-110029</p>
              </div>
              <div>
                <h3>Lets Talk</h3>
                <p>M No. +91-7503576834, Landline-011-46011334</p>
              </div>
              <div>
                <h3>General Support</h3>
                <p>Shashank@liteagle.com, info@liteagle.com</p>
              </div>
            </div>
          </div>
          <div className="second-container">
            <h2>Send Us A Message</h2>
            <form action="https://formspree.io/f/xnqlrbwe" method="post">
              <div className="form-group">
                <label for="name-input">Tell us your name*</label>
                <input
                  id="name-input"
                  type="text"
                  placeholder="First name"
                  name="fname"
                  required=""
                />
                <input
                  type="text"
                  placeholder="Last name"
                  name="lname"
                  required=""
                />
              </div>
              <div className="form-group">
                <label for="email-input">Enter your email</label>
                <input
                  id="email-input"
                  type="text"
                  placeholder="Eg. example@gmail.com"
                  name="email"
                  required=""
                />
              </div>
              <div className="form-group">
                <label for="phone-input">Enter phone number</label>
                <input
                  id="phone-input"
                  type="text"
                  placeholder="Eg. +91-1234567890"
                  name="phone"
                  required=""
                />
              </div>
              <div className="form-group">
                <label for="message-textarea">Message</label>
                <input
                  className="textarea"
                  id="message-textarea"
                  name="message"
                  placeholder="Write us a message"
                ></input>
              </div>
              <button type="submit">Send Message</button>
            </form>
          </div>
        </div>
      </div>

      <div className="cu-map" data-aos="fade-up">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.2124473043955!2d77.18751251508108!3d28.563382582445126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1daf8b1c2cf9%3A0xa5b7bd8dbe6550dd!2sLiteagle%20LLP!5e0!3m2!1sen!2sin!4v1632579179385!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          title="map"
        ></iframe>
      </div>
      <Footer />
    </>
  );
};

export default contact;
